<template>
  <div>
    <v-container
      fluid
      class="down-top-padding"
    >
      <BaseBreadcrumb
        :title="selectedUser.firstName"
        :icon="page.icon"
        :breadcrumbs="breadcrumbs"
      />
      <v-row>
        <v-col
          sm="12"
          lg="12"
          md="12"
        >
          <v-card class="mb-5">
            <v-card-text class="pa-5">
              <v-toolbar flat>
                <v-spacer />
                <template
                  v-if="
                    selectedUser.verified === true &&
                      selectedUser.active === true &&
                      loggedInUser.userType === 'Administrator'
                  "
                >
                  <v-btn
                    outlined
                    class="mr-4"
                    color="red darken-2"
                    @click="archive"
                  >
                    <v-icon small>
                      mdi-delete
                    </v-icon>
                    Archive
                  </v-btn>
                </template>
              </v-toolbar>

              <v-toolbar flat>
                <v-toolbar-title>Registrant Details</v-toolbar-title>
                <v-spacer />
                <v-menu
                  bottom
                  left
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      text
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ defaultClientDetailType }}
                      <v-icon>mdi-menu-down-outline</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item
                      v-for="(item, i) in clientDetailTypes"
                      :key="i"
                    >
                      <v-list-item-title
                        @click="changeClientDetailsScreen(item)"
                      >
                        {{ item }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-toolbar>
              <v-divider />
              <v-row>
                <v-col cols="4">
                  <center>
                    <v-avatar
                      color="indigo"
                      size="100"
                    >
                      <v-icon dark>
                        mdi-account-circle
                      </v-icon>
                    </v-avatar>
                  </center>
                  <h1
                    style="font-size: 1rem; text-align: center"
                    class="mt-2"
                  >
                    {{ selectedUser.firstName }}
                  </h1>
                  <p style="text-align: center">
                    <a :href="`mailto: ${selectedUser.email}`">{{
                      selectedUser.email
                    }}</a>
                  </p>
                </v-col>
                <v-divider vertical />
                <v-col cols="7">
                  <template v-if="defaultClientDetailType === 'General'">
                    <v-row>
                      <v-col
                        cols="12"
                        md="4"
                      >
                        <h3
                          class="darken-2"
                          style="font-size: 1rem; color: grey"
                        >
                          Given Name
                        </h3>
                        <br>
                        <p>
                          {{ selectedUser.contact.givenName || 'Not Provided' }}
                        </p>
                        <v-divider />
                      </v-col>
                      <v-col
                        cols="12"
                        md="4"
                      >
                        <h3
                          class="darken-2"
                          style="font-size: 1rem; color: grey"
                        >
                          Surname
                        </h3>
                        <br>
                        <p>
                          {{ selectedUser.contact.surname || 'Not Provided' }}
                        </p>
                        <v-divider />
                      </v-col>
                      <v-col
                        cols="12"
                        md="4"
                      >
                        <h3
                          class="darken-2"
                          style="font-size: 1rem; color: grey"
                        >
                          Preferred Name
                        </h3>
                        <br>
                        <p>
                          {{
                            selectedUser.contact.preferredName || 'Not Provided'
                          }}
                        </p>
                        <v-divider />
                      </v-col>
                      <v-col
                        cols="12"
                        md="4"
                      >
                        <h3
                          class="darken-2"
                          style="font-size: 1rem; color: grey"
                        >
                          Date of Birth
                        </h3>
                        <br>
                        <p>
                          {{
                            selectedUser.contact.dob
                              ? formatDate(selectedUser.contact.dob)
                              : 'Not Provided'
                          }}
                        </p>
                        <v-divider />
                      </v-col>
                      <v-col
                        cols="12"
                        md="4"
                      >
                        <h3
                          class="darken-2"
                          style="font-size: 1rem; color: grey"
                        >
                          User Type
                        </h3>
                        <br>
                        <p>{{ selectedUser.role || 'Not Provided' }}</p>
                        <v-divider />
                      </v-col>
                      <v-col
                        cols="12"
                        md="4"
                      >
                        <h3
                          class="darken-2"
                          style="font-size: 1rem; color: grey"
                        >
                          Mobile
                        </h3>
                        <br>
                        <p>
                          {{ selectedUser.contact.phone || 'Not Provided' }}
                        </p>
                        <v-divider />
                      </v-col>
                      <v-col
                        cols="12"
                        md="4"
                      >
                        <h3
                          class="darken-2"
                          style="font-size: 1rem; color: grey"
                        >
                          Club
                        </h3>
                        <br>
                        <p>
                          {{ clubNameByStateId(selectedUser.location.state) || 'Not Provided' }}
                        </p>
                        <v-divider />
                      </v-col>
                    </v-row>
                  </template>
                  <template v-if="defaultClientDetailType === 'Location'">
                    <v-row>
                      <v-col
                        cols="12"
                        md="4"
                      >
                        <h3
                          class="darken-2"
                          style="font-size: 1rem; color: grey"
                        >
                          Address Line 1
                        </h3>
                        <br>
                        <p>
                          {{
                            selectedUser.location.address_line_1 ||
                              'Not Provided'
                          }}
                        </p>
                        <v-divider />
                      </v-col>
                      <v-col
                        cols="12"
                        md="4"
                      >
                        <h3
                          class="darken-2"
                          style="font-size: 1rem; color: grey"
                        >
                          Address Line 2
                        </h3>
                        <br>
                        <p>
                          {{
                            selectedUser.location.address_line_2 ||
                              'Not Provided'
                          }}
                        </p>
                        <v-divider />
                      </v-col>
                      <v-col
                        cols="12"
                        md="4"
                      >
                        <h3
                          class="darken-2"
                          style="font-size: 1rem; color: grey"
                        >
                          Suburb
                        </h3>
                        <br>
                        <p>
                          {{ selectedUser.location.suburb || 'Not Provided' }}
                        </p>
                        <v-divider />
                      </v-col>
                      <v-col
                        cols="12"
                        md="4"
                      >
                        <h3
                          class="darken-2"
                          style="font-size: 1rem; color: grey"
                        >
                          State
                        </h3>
                        <br>
                        <p>
                          {{ selectedUser.location.state || 'Not Provided' }}
                        </p>
                        <v-divider />
                      </v-col>
                      <v-col
                        cols="12"
                        md="4"
                      >
                        <h3
                          class="darken-2"
                          style="font-size: 1rem; color: grey"
                        >
                          Postcode
                        </h3>
                        <br>
                        <p>
                          {{ selectedUser.location.postcode || 'Not Provided' }}
                        </p>
                        <v-divider />
                      </v-col>
                      <v-col
                        cols="12"
                        md="4"
                      >
                        <h3
                          class="darken-2"
                          style="font-size: 1rem; color: grey"
                        >
                          Country
                        </h3>
                        <br>
                        <p>
                          {{ selectedUser.location.country || 'Not Provided' }}
                        </p>
                        <v-divider />
                      </v-col>
                    </v-row>
                  </template>
                  <template v-if="defaultClientDetailType === 'Medical'">
                    <v-row>
                      No Data
                    </v-row>
                  </template>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          sm="12"
          lg="12"
          md="12"
        >
          <v-card class="mb-5">
            <v-card-text class="pa-5">
              <v-toolbar flat>
                Order Details
              </v-toolbar>

              <v-data-table
                :items="selectedUserOrders"
                :headers="headers"
              >
                <template v-slot:item.action="{ item }">
                  <div class="d-flex">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="success"
                          dark
                          v-bind="attrs"
                          icon
                          v-on="on"
                          @click="editItem(item)"
                        >
                          <v-icon>mdi-pencil-box-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>Edit</span>
                    </v-tooltip>
                  </div>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import { utilities } from '../../../../../mixins/utility'
  import moment from 'moment'
  export default {
    name: 'Registrant',
    mixins: [utilities],
    data () {
      return {
        headers: [
          { text: 'Registrant', value: 'person.contact.preferredName' },
          { text: 'Order Status', value: 'order_status' },
          { text: 'Payment Status', value: 'payment_status' },
          { text: 'Action', value: 'action' },
        ],
        page: {
          title: 'Your Roster',
          icon: 'user',
        },
        breadcrumbs: [
          {
            text: 'Users',
            disabled: false,
            to: '/user/users/',
          },
          {
            text: 'User Details',
            disabled: true,
          },
        ],
        auditHeaders: [
          {
            text: 'Reason',
            value: 'reason',
          },
          {
            text: 'Resource',
            value: 'route',
          },
          {
            text: 'Method',
            value: 'method',
          },
          {
            text: 'When',
            value: 'createdAt',
          },
        ],
        clientDetailTypes: ['General', 'Location', 'Medical'],
        defaultClientDetailType: 'General',
      }
    },
    computed: {
      ...mapGetters(['selectedUser', 'selectedUserOrders', 'clubNameByStateId']),
    },
    created () {
      this.$store.dispatch('viewUser', this.$route.params.id)
      this.$store.dispatch('viewUserOrder', this.$route.params.id)
      this.getAllClubs()
    },
    methods: {
      ...mapActions(['getAllClubs']),
      formatDate (timestamp) {
        return moment(timestamp).format('LL')
      },
      back () {
        this.$router.back()
      },
      changeClientDetailsScreen (screen) {
        this.defaultClientDetailType = screen
      },
      approve () {
        this.$store.dispatch('approveRegistrant', this.registrantQuery._id)
      },
      refreshAudit () {
        this.$store.dispatch('showUserAudit')
      },
      editItem (item) {
        this.$router.push(`/admin/orders/${item._id}`)
      },
    },
  }
</script>
